var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('HeaderVue',{staticClass:"mb-3",attrs:{"heading":"Bullet Description"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('div',{staticClass:"d-flex align-items-between"},[_c('AddCategory',{ref:"addTagModal",attrs:{"categoryTitle":{singular:'description', plural:'descriptions'},"isLoading":_vm.isLoadAddTags},on:{"submit":function($event){return _vm.saveTags($event)}}})],1)]},proxy:true}])}),_c('div',{staticClass:"card border-radius"},[_c('div',{staticClass:"card-body"},[_c('div',{},[_c('FilterBox',{attrs:{"search":true,"placeholder":"Search Bullet Description..."},model:{value:(_vm.titleInput),callback:function ($$v) {_vm.titleInput=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"titleInput"}}),_c('div',{staticClass:"loader d-flex align-items-center justify-content-between"},[(_vm.isLoading)?_c('div',{staticClass:"d-flex align-items-center"},[_c('b-spinner',{staticStyle:{"color":"#4d1b7e"},attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Loading Bullet Description")])],1):_vm._e()]),(_vm.isLoadingFirstTime)?_c('div',{staticClass:"skeleton"},[_c('b-skeleton-table',{staticClass:"py-3",attrs:{"rows":5,"columns":4,"table-props":{
                bordered: true,
                striped: true,
              }}})],1):(_vm.items.length>0)?_c('div',{staticClass:"data-table"},[_c('b-table',{staticClass:"leadTable",attrs:{"head-class":"text-center","stickyColumn":true,"hover":"","fields":_vm.fields,"items":_vm.items},scopedSlots:_vm._u([{key:"head(checkbox)",fn:function(){return [_c('b-form-checkbox',{staticClass:"checkbox",attrs:{"size":"lg"},on:{"input":function($event){return _vm.toggleQuizellTags()}},model:{value:(_vm.selectAllQuizellBulletDescription),callback:function ($$v) {_vm.selectAllQuizellBulletDescription=$$v},expression:"selectAllQuizellBulletDescription"}})]},proxy:true},{key:"cell(checkbox)",fn:function(data){return [_c('b-form-checkbox',{staticClass:"checkbox",attrs:{"value":data.item.id,"size":"lg"},model:{value:(_vm.selectedQuizellBulletDescription),callback:function ($$v) {_vm.selectedQuizellBulletDescription=$$v},expression:"selectedQuizellBulletDescription"}})]}},{key:"cell(title)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"text-hover-underline",on:{"click":function($event){return _vm.$refs.EditDescriptionModal.open({title:data.item.title,image:''})}}},[_vm._v(_vm._s(data.item.title))])])]}},{key:"cell(actions)",fn:function(data){return [_c('button',{staticClass:"btn",on:{"click":()=>{
      _vm.editDescriptionId=data.item.id;
      _vm.$refs.EditDescriptionModal.open({title:data.item.title,image:''})
    }}},[_c('svg',{staticClass:"bi-pencil-square b-icon bi",attrs:{"viewBox":"0 0 16 16","width":"1em","height":"1em","focusable":"false","role":"img","aria-label":"pencil square","xmlns":"http://www.w3.org/2000/svg","fill":"currentColor"}},[_c('g',[_c('path',{attrs:{"d":"M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"}}),_c('path',{attrs:{"fill-rule":"evenodd","d":"M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"}})])])])]}}])})],1):[_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"height":"50vh"}},[_c('EmptyTable',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" No bullet found ")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Time to fill your shelves or refine your search. Remember, your results will appear right here. ")]},proxy:true}])})],1)],(_vm.items.length)?_c('QuizellPagination',{attrs:{"total-rows":_vm.totalRows,"currentPage":_vm.currentPage,"per-page":_vm.perPage},on:{"update":_vm.updatePagination,"UpdatePerPage":_vm.updatePerPage}}):_vm._e()],2)])]),_c('DeleteFooterModal',{ref:"deleteModal",attrs:{"isLoading":_vm.isLoadingDeleteTag,"itemLength":_vm.selectedQuizellBulletDescription.length,"name":"bullet description"},on:{"click":function($event){return _vm.deleteSelectedTags()}}}),_c('ViewCategoryProducts',{ref:"viewProducts",attrs:{"category":"description"}}),_c('AddCategory',{ref:"EditDescriptionModal",attrs:{"editMode":true,"categoryTitle":{singular:'description', plural:'description'},"isLoading":_vm.isLoadAddTags},on:{"submit":function($event){return _vm.saveTags($event,true)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }